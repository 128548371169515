import { render, staticRenderFns } from "./NotificationError.vue?vue&type=template&id=28e2a600&scoped=true"
import script from "./NotificationError.vue?vue&type=script&lang=js"
export * from "./NotificationError.vue?vue&type=script&lang=js"
import style0 from "./NotificationError.vue?vue&type=style&index=0&id=28e2a600&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e2a600",
  null
  
)

export default component.exports