import { render, staticRenderFns } from "./WalletWithdrawModalTop.vue?vue&type=template&id=0ca0a3af&scoped=true"
import script from "./WalletWithdrawModalTop.vue?vue&type=script&lang=js"
export * from "./WalletWithdrawModalTop.vue?vue&type=script&lang=js"
import style0 from "./WalletWithdrawModalTop.vue?vue&type=style&index=0&id=0ca0a3af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ca0a3af",
  null
  
)

export default component.exports